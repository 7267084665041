//react componentes
//mui componentes
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
//sqlitecloud components
import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)`
  display: ${(props) => (props.showNavTree ? "block" : "none")};
  width: ${(props) =>
    props.isMobile
      ? "100%"
      : props.hideSideBarOnDesk
        ? props.sideBarWidthDesk.closed
        : props.sideBarWidthDesk.open};
  border-right: ${(props) =>
    props.isMobile ? "0" : " 1px solid rgba(0, 0, 0, 0.10)"};
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    padding: ${(props) => (props.isMobile ? "0 1rem 0" : "0 1rem 0")};
    border: 0;
  }
`;

const Sidebar = ({
  isMobile = false,
  sidebarTree,
  showNavTree,
  open,
  isProjectsHome,
  sideBarWidthDesk,
  hideSideBarOnDesk,
  handleHideSideBarOnDesk,
  onDrawerToggle,
  ...rest
}) => {
  //render
  return (
    <Box
      sx={{
        flexShrink: "0",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Drawer
        sx={{ height: "100%" }}
        variant={isMobile ? "temporary" : "permanent"}
        open={open}
        isMobile={isMobile}
        sideBarWidthDesk={sideBarWidthDesk}
        hideSideBarOnDesk={hideSideBarOnDesk}
        showNavTree={showNavTree}
        {...rest}
      >
        <SidebarNav
          sidebarTree={sidebarTree}
          show={showNavTree}
          open={open}
          isProjectsHome={isProjectsHome}
          isMobile={isMobile}
          hideSideBarOnDesk={hideSideBarOnDesk}
          handleHideSideBarOnDesk={handleHideSideBarOnDesk}
          onDrawerToggle={onDrawerToggle}
        />
      </Drawer>
    </Box>
  );
};

export default Sidebar;
