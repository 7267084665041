import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { SQLiteCloudSearch } from "./SQLiteCloudSearch";
import { useDocSearchKeyboardEvents } from "./useDocSearchKeyboardEvents";

interface SearchIconProps extends React.SVGProps<SVGSVGElement> {
  // Add any additional custom props here if needed
  // e.g., color?: string;
}

const SearchIconWithInput: React.FC<SearchIconProps> = (props) => {
  return (
    <svg
      viewBox="0 0 17 17"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.14567 14.8748C11.8621 14.8748 14.8748 11.8621 14.8748 8.14567C14.8748 4.42925 11.8621 1.4165 8.14567 1.4165C4.42925 1.4165 1.4165 4.42925 1.4165 8.14567C1.4165 11.8621 4.42925 14.8748 8.14567 14.8748Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5832 15.5832L14.1665 14.1665"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const SearchIconMinimal: React.FC<SearchIconProps> = (props) => {
  return (
    <>
      <div className="tw-block group-hover:tw-hidden">
        <svg
          width="40"
          height="36"
          viewBox="0 0 40 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="39"
            height="35"
            rx="17.5"
            stroke="#D8D8D8"
          />
          <path
            d="M19.6668 11.3333C23.1668 11.3333 26.0002 14.1667 26.0002 17.6667C26.0002 21.1667 23.1668 24 19.6668 24C16.1668 24 13.3335 21.1667 13.3335 17.6667C13.3335 15.2 14.7402 13.0667 16.8002 12.02"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.6668 24.6667L25.3335 23.3333"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="tw-hidden group-hover:tw-block">
        <svg
          width="40"
          height="36"
          viewBox="0 0 40 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.7">
            <rect
              x="0.5"
              y="0.5"
              width="39"
              height="35"
              rx="17.5"
              stroke="black"
            />
            <path
              d="M19.6668 11.3333C23.1668 11.3333 26.0002 14.1667 26.0002 17.6667C26.0002 21.1667 23.1668 24 19.6668 24C16.1668 24 13.3335 21.1667 13.3335 17.6667C13.3335 15.2 14.7402 13.0667 16.8002 12.02"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M26.6668 24.6667L25.3335 23.3333"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
    </>
  );
};

// Types definition
type StyleType = "only-icon" | "with-search-input";
type AlignType = "left" | "right";
type TargetType = "_blank" | "_parent" | "_self" | "_top";
type SearchSourceType = "doc" | "blog" | "doc_blog";

interface SearchProps {
  type?: AlignType;
  maxWidth?: string;
  bgColor?: string;
  style?: StyleType;
  targetLink?: TargetType;
  relativeUrl?: boolean;
  sidebarId?: string;
  modalInputPlaceholder?: string;
  enableModifierKey?: boolean;
  hiddenClass?: string;
  searchSource?: SearchSourceType;
}

export function Search({
  type = "left",
  maxWidth = "lg:tw-max-w-56",
  bgColor = "tw-bg-transparent",
  style = "with-search-input",
  targetLink = "_self",
  relativeUrl = false,
  sidebarId = "",
  enableModifierKey = true,
  hiddenClass = "invisible",
  searchSource = "doc",
  modalInputPlaceholder = "Search docs...",
}: SearchProps) {
  let [isOpen, setIsOpen] = useState(false);
  let [modifierKey, setModifierKey] = useState();

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useDocSearchKeyboardEvents({ isOpen, onOpen, onClose, enableModifierKey });

  useEffect(() => {
    setModifierKey(
      // @ts-expect-error: Unreachable code error
      /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? "⌘" : "Ctrl "
    );
  }, []);

  let buttonClass =
    "tw-group tw-flex tw-h-10 tw-w-full tw-items-center tw-justify-center sm:tw-justify-start tw-rounded-3xl tw-px-4 tw-py-2 tw-text-12px-reg tw-text-black tw-text-opacity-50 tw-ring-1 tw-ring-black tw-ring-opacity-20 tw-hover:ring-opacity-50";

  switch (type) {
    case "left":
      buttonClass = buttonClass + " tw-rounded-bl-none";
      break;

    case "right":
      buttonClass = buttonClass + " tw-rounded-br-none";
      break;

    default:
      buttonClass = buttonClass + " tw-rounded-br-none";
      break;
  }

  buttonClass = buttonClass + " " + maxWidth;
  buttonClass = buttonClass + " " + bgColor;

  return (
    <>
      {style === "with-search-input" && (
        <button type="button" className={buttonClass} onClick={onOpen}>
          <SearchIconWithInput className="tw-h-5 tw-w-5 tw-flex-none tw-stroke-brand" />
          <span className="tw-ml-2">Search docs</span>
          {modifierKey && (
            <kbd className="tw-ml-auto tw-block tw-text-black tw-text-opacity-50">
              <kbd className="tw-font-sans">{modifierKey}</kbd>
              <kbd className="tw-font-sans">K</kbd>
            </kbd>
          )}
        </button>
      )}
      {style === "only-icon" && (
        <button className="tw-group" type="button" onClick={onOpen}>
          <SearchIconMinimal className="tw-h-9 tw-w-10 tw-flex-none" />
        </button>
      )}
      {isOpen &&
        createPortal(
          <SQLiteCloudSearch
            modalInputPlaceholder={modalInputPlaceholder}
            searchSource={searchSource}
            hiddenClass={hiddenClass}
            sidebarId={sidebarId}
            targetLink={targetLink}
            relativeUrl={relativeUrl}
            close={setIsOpen}
          />,
          document.body
        )}
    </>
  );
}
