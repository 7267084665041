//react
//next.js componentes
import Grid from "@mui/material/Grid";
import Link from "next/link";
//sqlitecloud componenets
import Breadcrumbs from "@components/Breadcrumbs";
import Menu from "./Menu";

const NavBar = ({
  mobileOpen,
  isProjectsHome,
  isFirstProject,
  isMobile,
  onDrawerToggle,
}) => {
  //render
  return (
    <Grid
      container
      direction={"column"}
      rowSpacing={12}
      sx={{
        padding: isMobile
          ? "2.5rem 1rem 2.875rem 1rem"
          : "1.5rem 1.5rem 1.375rem 2.625rem",
        background: "white",
      }}
    >
      <Grid item>
        <Grid
          container
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Grid
              container
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              {(isMobile || isProjectsHome) && (
                <Link href="/" passHref legacyBehavior>
                  <a className="unstyled-link">
                    <img
                      style={{
                        width: "2.5rem",
                        background: "transparent",
                        cursor: "pointer",
                      }}
                      alt="SQlite Cloud"
                      src="/static/img/logo/logo-light@4x.png"
                    />
                  </a>
                </Link>
              )}
              {!isMobile && !isProjectsHome && (
                <Grid item>
                  <Breadcrumbs />
                </Grid>
              )}
            </Grid>
          </Grid>
          {!mobileOpen && (
            <Grid item>
              <Menu
                isMobile={isMobile}
                onDrawerToggle={onDrawerToggle}
                isProjectsHome={isProjectsHome}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {isMobile && !isProjectsHome && !isFirstProject && (
        <Grid item>
          <Grid item>
            <Breadcrumbs />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default NavBar;
