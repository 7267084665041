//react components
import { useEffect, useState } from "react";
//next.js
import Link from "next/link";
//mui components
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
//sqlitecloud lib
import {
  useGetActualProjectId,
  useGetProjects,
  useGetUser,
} from "@custom-hooks/useApi";

function Breadcrumbs() {
  //layout
  const theme = useTheme();
  //get users project
  const { projects: userProjects } = useGetProjects();
  //get actual user
  const { user } = useGetUser();
  //get actual project id
  const projectId = useGetActualProjectId();
  //name of selected project
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  useEffect(() => {
    if (projectId && userProjects) {
      userProjects.forEach((project) => {
        if (project.id == projectId) {
          setSelectedProjectName(project.name);
        }
      });
    }
  }, [projectId, userProjects]);

  //render
  return (
    <Grid container alignItems={"center"}>
      <Grid item>
        <Link href="/" passHref legacyBehavior>
          <a className="unstyled-link">
            <Typography
              fontSize={{ xs: "0.9375rem", md: "1rem" }}
              lineHeight={{ xs: "160%", md: "125%" }}
              fontWeight="400"
              color={theme.palette.secondary.accent3}
              sx={{
                cursor: "pointer",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "15rem",
              }}
            >
              {user ? user.name : "..."}
            </Typography>
          </a>
        </Link>
      </Grid>
      {projectId && (
        <>
          <Grid item>
            <Grid container justifyContent={"center"}>
              <ChevronRightIcon
                sx={{ color: theme.palette.neutral.lightGrey }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              fontSize={{ xs: "0.9375rem", md: "1rem" }}
              lineHeight={{ xs: "160%", md: "125%" }}
              fontWeight="500"
              color={theme.palette.secondary.accent2}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "15rem",
              }}
            >
              {selectedProjectName ? selectedProjectName : "..."}
            </Typography>
          </Grid>
          {/* {
						path.map((pathEl, i) =>
							<Fragment key={i}>
								<Grid item>
									<Grid item>
										<Grid container justifyContent={"center"}>
											<ChevronRightIcon sx={{ color: theme.palette.neutral.lightGrey }} />
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<Typography fontSize="1rem" lineHeight={"125%"} fontWeight="500" color={pathEl.experimental ? theme.palette.error.main : theme.palette.secondary.accent2}>
										{pathEl.title}
									</Typography>
								</Grid>
							</Fragment>
						)
					} */}
        </>
      )}
    </Grid>
  );
}

export default Breadcrumbs;
