//react component
import { Fragment, useEffect, useRef, useState } from "react";
//next.js
import { useRouter } from "next/router";
//mui components
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
//sqlitecloud lib
import { signOut } from "@lib/client-side-fetcher";
//sqlitecloud custom hooks
import { useGetUser } from "@custom-hooks/useApi";
//sqlitecloud components
import Avatar from "@components/GenericElements/Avatar";
import Profile from "@components/User/Profile";
import OpenDialog from "@generic-components/OpenDialog";
//sqlitecloud libs
import ProfileIcon from "@layouts/svg-icon/profile-icon.svg";
import SignOutIcon from "@layouts/svg-icon/sign-out-icon.svg";

function NavbarUserDropdown({ isMobile = false }) {
  const profileRefDialog = useRef();
  const router = useRouter();
  const { profile } = router.query; // Access the query parameters
  useEffect(() => {
    if (profile) {
      setAnchorMenu(true);
      setTimeout(() => {
        profileRefDialog.current.open();
      }, 200);
    }
  }, [profile, profileRefDialog]);
  //get simulation generic user from context
  const { user: userInfo } = useGetUser();
  //handle dropdown menu opening
  const [anchorMenu, setAnchorMenu] = useState(null);
  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };
  //define profile menu item
  const ProfileMenuItem = ({ callback }) => {
    return <MenuItem onClick={callback}>Profile</MenuItem>;
  };
  const ProfileMobile = ({ callback }) => {
    return (
      <IconButton
        sx={{ padding: 0 }}
        onClick={callback}
        color="primary"
        disableFocusRipple={true}
        disableRipple={true}
      >
        <Box
          padding={"0.75rem 1.75rem 0.75rem 1rem"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          gap={"0.5rem"}
          display={"inline-flex"}
        >
          <ProfileIcon />
          <Typography
            fontFamily={"SuisseIntl"}
            variant="14px-reg"
            fontWeight={"450"}
          >
            Profile
          </Typography>
        </Box>
      </IconButton>
    );
  };
  if (isMobile) {
    return (
      <>
        <OpenDialog
          size="small-modal"
          style="custom"
          actionOnClose={closeMenu}
          actionComponent={<ProfileMobile />}
        >
          <Profile />
        </OpenDialog>
        <IconButton
          sx={{ padding: 0 }}
          onClick={async () => {
            signOut();
          }}
          color="primary"
          disableFocusRipple={true}
          disableRipple={true}
        >
          <Box
            padding={"0.75rem 1.75rem 0.75rem 1rem"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={"0.5rem"}
            display={"inline-flex"}
          >
            <SignOutIcon />
            <Typography
              fontFamily={"SuisseIntl"}
              variant="14px-reg"
              fontWeight={"450"}
            >
              Sign out
            </Typography>
          </Box>
        </IconButton>
      </>
    );
  }
  return (
    <Fragment>
      <Tooltip title={"User settings"}>
        <span>
          <IconButton
            sx={{ padding: 0 }}
            aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
            aria-haspopup="true"
            onClick={toggleMenu}
            color="primary"
            disableFocusRipple={true}
            disableRipple={true}
          >
            {userInfo && <Avatar name={userInfo?.name} />}
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <OpenDialog
          ref={profileRefDialog}
          size="small-modal"
          style="custom"
          actionOnClose={closeMenu}
          actionComponent={<ProfileMenuItem />}
        >
          <Profile />
        </OpenDialog>
        <MenuItem
          onClick={async () => {
            signOut();
          }}
        >
          Sign out
        </MenuItem>
      </Menu>
    </Fragment>
  );
}

export default NavbarUserDropdown;
