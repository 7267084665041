function ProSvg() {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.16222 7.53099L6.16041 1.24253C6.55207 0.750959 7.28371 1.05663 7.28371 1.71153V6.57897C7.28371 6.97176 7.57121 7.2891 7.92536 7.2891H10.357C10.9094 7.2891 11.2036 8.01011 10.8386 8.46979L5.84042 14.7575C5.44877 15.249 4.71712 14.9434 4.71712 14.2885V9.42103C4.71712 9.02824 4.42963 8.7109 4.07547 8.7109H1.64304C1.09055 8.7109 0.79723 7.98989 1.16222 7.53099Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ProSvg;
