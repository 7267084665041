//react components
import React, { useContext, useEffect, useState } from "react";
//next.js
// import { useRouter } from "next/compat/router";
// import { useSearchParams, usePathname } from "next/navigation";
//css library
import styled from "@emotion/styled";
//mui components
import GlobalStyle from "@components/GlobalStyle";
import NavBar from "@components/navbar/NavBar";
import Sidebar from "@components/sidebar/Sidebar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiLinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
const LinearProgress = styled(MuiLinearProgress)(spacing);
//sqlitecloud library
import { useGetSidebarTree } from "@layouts/lib/useGetSidebarTree";
import { generateRandomId } from "@lib/utils";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud hooks
import {
  useGetActualProjectId,
  useGetProjects,
  useGetUser,
  useUploadDatabase,
} from "@custom-hooks/useApi";
import { useCustomRouter } from "@custom-hooks/useCustomRouter";
//sqlitecloud components
import SnackNotification from "@generic-components/SnackNotification";

const sideBarWidthDesk = {
  open: "15.375rem",
  closed: "5.3rem",
};

const Dashboard = ({ children }) => {
  //get theme
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    if (!isMobile) {
      setMobileOpen(false);
    }
    if (isMobile) {
      setHideSideBarOnDesk(false);
    }
  }, [isMobile]);
  //read user data
  const { user } = useGetUser();
  //read parameters from url
  const { pathname, router } = useCustomRouter();
  useEffect(() => {
    setMobileOpen(false);
  }, [router]);
  const slug = pathname.split("/").pop();
  //get user projects
  const { projects: userProjects } = useGetProjects();
  //read stateContext
  const { setTabId } = useContext(StateContext);
  useEffect(() => {
    const tabId = "sqlitecloud-tab-" + generateRandomId();
    setTabId(tabId);
  }, []);
  //builds obj dedicate to the sidebar
  const sidebarTree = useGetSidebarTree();
  //get if the user has zero project
  const isFirstProject = userProjects && userProjects.length === 0;
  //get actual project id
  const projectId = useGetActualProjectId();
  //one received user projects and built obj dedicated to sidebar, based on acutal project id and the user projects decides if redirect, or if we are in homepage or subpages
  const isProjectsHome = sidebarTree ? false : true;
  //handle redirect based on project id and nodes count
  useEffect(() => {
    if (userProjects) {
      //if projectId is false means that it is not a project of the current user. so redirect to 404 page
      if (projectId === false) {
        router.replace(`/not-found`);
      } else if (projectId) {
        //if projectId exists but the number of node is zero, redirect the user to the ndoes page
        userProjects.forEach((project) => {
          if (project.id == projectId && project.nodes_count === 0) {
            if (slug !== "nodes" && slug !== "") {
              router.push(`/projects/${projectId}/nodes`);
            }
          }
        });
      }
    }
  }, [userProjects, projectId, slug]);

  //helper function to filter download and upload queue based on projectId
  function filterMapBasedOnProjectId(map, id) {
    let filteredItems = new Map();
    map.forEach((value, key) => {
      if (value.projectId === id) {
        filteredItems.set(key, value);
      }
    });
    return filteredItems;
  }

  //upload databases
  const { uploadQueue, uploadDb } = useUploadDatabase();
  const uploadOpts = {
    uploadQueue: projectId
      ? filterMapBasedOnProjectId(uploadQueue, projectId)
      : uploadQueue,
    uploadDb,
  };

  //handle main sidebar open state from mobile
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  //handle hide sidebar on desktop
  const [hideSideBarOnDesk, setHideSideBarOnDesk] = useState(false);
  const handleHideSideBarOnDesk = (state) => {
    setHideSideBarOnDesk(state);
  };
  //render
  return (
    <>
      <CssBaseline />
      <GlobalStyle />
      {user && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
              minHeight: "100%",
            }}
          >
            <Box
              sx={{
                flex: "none",
              }}
            />
            <Box
              sx={{
                height: "100vh",
                flex: "1 1 0px",
                boxSizing: "border-box",
                minHeight: "0",
                height: "100vh",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  background: "white",
                }}
              >
                <Sidebar
                  sidebarTree={sidebarTree}
                  isMobile={isMobile}
                  open={mobileOpen}
                  hideSideBarOnDesk={hideSideBarOnDesk}
                  handleHideSideBarOnDesk={handleHideSideBarOnDesk}
                  uploadOpts={uploadOpts}
                  showNavTree={!isProjectsHome || isMobile}
                  sideBarWidthDesk={sideBarWidthDesk}
                  isProjectsHome={isProjectsHome}
                  onDrawerToggle={handleDrawerToggle}
                />
                <Box
                  sx={{
                    maxWidth: "100%",
                    display: "flex",
                    flex: "1 1 0%",
                    flexDirection: "column",
                    paddingTop: isMobile ? "0" : "0.625rem",
                    maxWidth:
                      isMobile || isProjectsHome || isFirstProject
                        ? "100%"
                        : hideSideBarOnDesk
                          ? `calc(100% - ${sideBarWidthDesk.closed})`
                          : `calc(100% - ${sideBarWidthDesk.open})`,
                  }}
                >
                  <NavBar
                    isProjectsHome={isProjectsHome}
                    isMobile={isMobile}
                    isFirstProject={isFirstProject}
                    onDrawerToggle={handleDrawerToggle}
                    mobileOpen={mobileOpen}
                  />
                  <Box
                    sx={{
                      flex: "1 1 0%",
                      flexGrow: "1",
                      boxSizing: "border-box",
                      overflowY: "auto",
                      // background: "pink",
                      padding: isProjectsHome
                        ? isMobile
                          ? "0 1rem"
                          : "1.25rem 1.5rem 0 2.625rem"
                        : isMobile
                          ? "0 1rem 1rem"
                          : "1.25rem 1.5rem 1.375rem 2.625rem",
                      overflowX: isFirstProject ? "hidden" : "auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {React.Children.map(children, (child) =>
                      React.cloneElement(child, { uploadOpts: uploadOpts })
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <SnackNotification />
        </>
      )}
    </>
  );
};

export default Dashboard;
