//react
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
//css library
import styled from "@emotion/styled";
//mui components
import MuiButton from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import ListItemButton from "@mui/material/ListItemButton";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { spacing } from "@mui/system";
const Button = styled(MuiButton)(spacing);
const Item = styled(ListItemButton)`
  width: 100%;
  margin-left: 8px;
  border-radius: 8px;
  cursor: "pointer";
  svg {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
  &:hover {
    background: transparent;
  }
  &:hover p {
    color: ${(props) => props.theme.palette.secondary.main};
  }
  &:hover svg {
    stroke: ${(props) => props.theme.palette.secondary.main};
  }
  &:hover span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;
const Title = styled(Typography)`
  box-sizing: border-box;
  color: ${(props) => props.theme.palette.secondary.accent4};
  padding: 0 ${(props) => props.theme.spacing(2)};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1;
  display: block;
  cursor: pointer;
`;
//sqlitecloud components
import ModalClosingButton from "@generic-components/ModalClosingButton";
import ModaleDecoration from "@generic-components/ModaleDecoration";

/**
 *
 * - size = "fit-modal" | "small-modal" | "medium-modal" | "large-modal"
 * - style = "custom" | "light" | "black" | "white" | "light-blue" | "dark-blue" | "primary-small" | "secondary-small"
 * - actionOnOpen, actionOnClose = function | [function1, function2, ...]
 *
 * */
const OpenDialog = forwardRef(
  (
    {
      label,
      children,
      size = "small-modal",
      style = "light-blue",
      decorationVariant = 1,
      actionComponent = undefined,
      disabled = false,
      actionOnOpen = null,
      actionOnClose = null,
      experimental = false,
      icon = null,
      childWithRef = false,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const dialogStateClass = open ? "open-dialog" : "closed-dialog";
    const handleOpen = (event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      setOpen(true);
      if (actionOnOpen) {
        if (Array.isArray(actionOnOpen) && actionOnOpen.length > 0) {
          actionOnOpen.forEach((action) => action());
        } else {
          actionOnOpen();
        }
      }
    };
    const handleClose = (event, reason, source) => {
      if (event && event.stopPropagation) {
        event.stopPropagation();
      }
      if (
        childRef.current &&
        ((reason &&
          (reason === "backdropClick" || reason === "escapeKeyDown")) ||
          (source && source === "close-button"))
      ) {
        childRef.current.checkOnClose();
        return;
      }
      setOpen(false);
      if (actionOnClose) {
        if (Array.isArray(actionOnClose) && actionOnClose.length > 0) {
          actionOnClose.forEach((action) => action());
        } else {
          actionOnClose();
        }
      }
    };
    const stopPropagationForKeyDown = (event) => {
      event.stopPropagation();
    };
    const stopPropagationOnClick = (event) => {
      event.stopPropagation();
    };
    //expose imperative methods to parent component
    useImperativeHandle(ref, () => ({
      open: () => {
        handleOpen();
      },
      close: () => {
        handleClose();
      },
    }));
    //get theme
    const theme = useTheme();
    //define ref to children
    const childRef = useRef(null);
    //render
    return (
      <>
        {style === "custom" && (
          <>
            {React.Children.map(actionComponent, (child) =>
              React.cloneElement(child, { callback: handleOpen })
            )}
          </>
        )}
        {style === "light" && (
          <Item
            onClick={handleOpen}
            sx={{ background: experimental ? theme.palette.error.main : "" }}
          >
            <Title sx={{ color: experimental ? "#FFF !important" : "" }}>
              {label}
            </Title>
          </Item>
        )}
        {style === "black" && (
          <Button
            onClick={handleOpen}
            disabled={disabled}
            variant="macro-action-primary"
            sx={{ background: experimental ? theme.palette.error.main : "" }}
            startIcon={icon}
          >
            {label}
          </Button>
        )}
        {style === "white" && (
          <Button
            onClick={handleOpen}
            disabled={disabled}
            variant="macro-action-secondary"
            sx={{
              background: experimental ? theme.palette.error.main : "",
              color: experimental ? "#FFF !important" : "",
            }}
            startIcon={icon}
          >
            {label}
          </Button>
        )}
        {style === "light-blue" && (
          <Button
            onClick={handleOpen}
            className={dialogStateClass}
            disabled={disabled}
            variant="light-blue-action"
            sx={{
              background: experimental ? theme.palette.error.main : "",
              color: experimental ? "#FFF !important" : "",
            }}
            startIcon={icon}
          >
            {label}
          </Button>
        )}
        {style === "dark-blue" && (
          <Button
            onClick={handleOpen}
            className={dialogStateClass}
            disabled={disabled}
            variant="dark-blue-action"
            sx={{
              background: experimental ? theme.palette.error.main : "",
              color: experimental ? "#FFF !important" : "",
            }}
            startIcon={icon}
          >
            {label}
          </Button>
        )}
        {style === "primary-small" && (
          <Button
            onClick={handleOpen}
            disabled={disabled}
            size="small"
            color="primary"
            sx={{ background: experimental ? theme.palette.error.main : "" }}
            startIcon={icon}
          >
            {label}
          </Button>
        )}
        {style === "secondary-small" && (
          <Button
            onClick={handleOpen}
            disabled={disabled}
            size="small"
            color="secondary"
            sx={{
              background: experimental ? theme.palette.error.main : "",
              color: experimental ? "#FFF !important" : "",
            }}
            startIcon={icon}
          >
            {label}
          </Button>
        )}
        <Dialog
          disableEnforceFocus
          open={open}
          onClose={handleClose}
          onKeyDown={stopPropagationForKeyDown}
          PaperProps={{
            className: size,
            sx: {
              overflow: "hidden",
            },
          }}
        >
          <ModalClosingButton
            handleClose={(event, reason) =>
              handleClose(event, reason, "close-button")
            }
          />
          <DialogContent
            onClick={stopPropagationOnClick}
            sx={{
              padding: "3rem 3rem 3.875rem 3rem",
            }}
          >
            {React.Children.map(children, (child) =>
              React.cloneElement(child, {
                callback: handleClose,
                ref: childWithRef ? childRef : undefined,
              })
            )}
          </DialogContent>
          <ModaleDecoration variant={decorationVariant} />
        </Dialog>
      </>
    );
  }
);

OpenDialog.displayName = "OpenDialog";

export default OpenDialog;
