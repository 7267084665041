//react components
//next.js componentes
import Link from "next/link";
//css library
import { css } from "@emotion/react";
import styled from "@emotion/styled";
//mui components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MuiIconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
//sqlitecloud hooks
import { useGetActualNodesNumber, useGetUser } from "@custom-hooks/useApi";
//sqlitecloud components
import Avatar from "@components/GenericElements/Avatar";
import OpenDialog from "@components/GenericElements/OpenDialog";
import CloseNavSVG from "@components/GenericElements/SVG/CloseNavSVG";
import HamburgerNavSVG from "@components/GenericElements/SVG/HamburgerNavSVG";
import HelpDropdown from "@components/navbar/HelpDropdown";
import Menu from "@components/navbar/Menu";
import NavbarUserDropdown from "@components/navbar/NavbarUserDropdown";
import SingleProjectInfo from "@components/Projects/SingleProjectInfo";
import SidebarNavSection from "./SidebarNavSection";

const IconButton = styled(MuiIconButton)``;

const baseScrollbar = (props) => css`
  background-color: ${props.theme.palette.primary.white};
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

const ProjectAddressButton = ({ isMobile, callback }) => {
  //get theme
  const theme = useTheme();
  //read actual project node
  const actualNodesNumber = useGetActualNodesNumber();
  //verify that nodes number is greater than zero
  const haveNodes = actualNodesNumber && actualNodesNumber > 0 ? true : false;
  //check if an item must be disabled
  const disableItem = !haveNodes;
  //render
  return (
    <Box
      sx={{
        position: "sticky",
        bottom: 0,
        width: "100%",
        padding: isMobile ? "1rem 0 1.625rem" : "1rem 0 2.375rem",
        display: "flex",
        justifyContent: "center",
        background: "white",
      }}
    >
      <Button
        variant="simple-text"
        onClick={callback}
        disabled={disableItem}
        sx={{
          margin: "0 auto",
          padding: "0.75rem 0.125rem",
          width: "13.375rem",
          height: "2.875rem",
          backgroundColor: theme.palette.neutral.pale,
          borderRadius: "0.5rem",
          border: `1px ${theme.palette.primary.main} solid`,
          wordWrap: "break-word",
        }}
      >
        <Typography fontFamily={"SuisseIntl"} variant="12px-demi">
          Connect client
        </Typography>
      </Button>
    </Box>
  );
};

const SidebarNav = ({
  sidebarTree,
  show,
  isProjectsHome,
  open,
  onDrawerToggle,
  isMobile,
  hideSideBarOnDesk,
  handleHideSideBarOnDesk,
  customPageSidebar,
}) => {
  //get theme
  const theme = useTheme();
  //get user info
  const { user: userInfo } = useGetUser();
  return (
    <>
      <Box>
        <Box
          sx={{
            padding: "2.5rem 0.5rem 2rem",
            position: "sticky",
            top: 0,
            zIndex: 50,
            background: "white",
            marginBottom: isMobile ? "10px" : undefined,
          }}
        >
          <Grid
            container
            flexDirection={hideSideBarOnDesk ? "column" : "row"}
            justifyContent={hideSideBarOnDesk ? "flex-start" : "space-between"}
            alignItems={"start"}
            flexWrap={"nowrap"}
            rowGap={2}
          >
            <Grid item>
              <Link href="/" passHref legacyBehavior>
                <a className="unstyled-link">
                  <img
                    style={{
                      width: "2.75rem",
                      background: "transparent",
                      cursor: "pointer",
                    }}
                    alt="SQlite Cloud"
                    src="/static/img/logo/logo-light@4x.png"
                  />
                </a>
              </Link>
            </Grid>
            {isMobile && (
              <Grid item>
                <Menu
                  open={open}
                  isMobile={isMobile}
                  onDrawerToggle={onDrawerToggle}
                  isProjectsHome={isProjectsHome}
                />
              </Grid>
            )}
            {!isMobile &&
              false && ( //remove false to show enable sidebar on desktop
                <>
                  {hideSideBarOnDesk && (
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        handleHideSideBarOnDesk(false);
                      }}
                      size="large"
                      sx={{ margin: 0, padding: 0 }}
                      disableRipple={true}
                    >
                      <HamburgerNavSVG />
                    </IconButton>
                  )}
                  {!hideSideBarOnDesk && (
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        handleHideSideBarOnDesk(true);
                      }}
                      size="large"
                      sx={{ margin: 0, padding: 0 }}
                      disableRipple={true}
                    >
                      <CloseNavSVG />
                    </IconButton>
                  )}
                </>
              )}
          </Grid>
        </Box>
        {isMobile && (
          <Box
            marginBottom={"2rem"}
            marginLeft={"0.5rem"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            gap={"1.5rem"}
            display={"inline-flex"}
          >
            <Box
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={"0.75rem"}
              display={"inline-flex"}
            >
              <Box
                width={"2.5rem"}
                height={"2.5rem"}
                justifyContent={"center"}
                alignItems={"center"}
                display={"flex"}
              >
                <Avatar width="2.5rem" height="2.5rem" name={userInfo?.name} />
              </Box>
              <Box
                width={"7.188rem"}
                flexDirection={"column"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                display={"inline-flex"}
              >
                <Typography variant={"12px-demi"}>{userInfo?.name}</Typography>
                <Typography variant={"12px-reg"}>{userInfo?.email}</Typography>
              </Box>
            </Box>
          </Box>
        )}
        {!hideSideBarOnDesk && (
          <List disablePadding sx={{ visibility: show ? "visible" : "hidden" }}>
            {sidebarTree && (
              <SidebarNavSection
                isProjectsHome={isProjectsHome}
                component="div"
                hideSideBarOnDesk={hideSideBarOnDesk}
                key={sidebarTree.title}
                pages={sidebarTree.pages}
                title={sidebarTree.title}
                isMobile={isMobile}
              />
            )}
          </List>
        )}
        {isMobile && (
          <>
            <Box
              marginTop={"1rem"}
              marginBottom={"1rem"}
              height={0}
              border={`1px ${theme.palette.neutral.lightGrey} solid`}
            />
            <Box
              flexDirection={"column"}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              gap={"1rem"}
              display={"flex"}
            >
              <Typography
                color={theme.palette.secondary.accent3}
                variant="12px-reg"
                fontWeight={"450"}
              >
                SUPPORT
              </Typography>
              <Box
                flexDirection={"column"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                display={"flex"}
              >
                <HelpDropdown isMobile={isMobile} />
              </Box>
            </Box>
            <Box
              marginTop={"1rem"}
              marginBottom={"1rem"}
              height={0}
              border={`1px ${theme.palette.neutral.lightGrey} solid`}
            />
            <Box
              flexDirection={"column"}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              gap={"1rem"}
              display={"flex"}
            >
              <Typography
                color={theme.palette.secondary.accent3}
                variant="12px-reg"
                fontWeight={"450"}
              >
                USER SETTING
              </Typography>
              <Box
                flexDirection={"column"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                display={"flex"}
              >
                <NavbarUserDropdown isMobile={isMobile} />
              </Box>
            </Box>
          </>
        )}
      </Box>
      {!hideSideBarOnDesk && !isProjectsHome && (
        <OpenDialog
          actionComponent={<ProjectAddressButton isMobile={isMobile} />}
          size="small-modal"
          style="custom"
          decorationVariant={2}
        >
          <SingleProjectInfo />
        </OpenDialog>
      )}
    </>
  );
};

export default SidebarNav;
