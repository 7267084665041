//react
import React from "react";
//next.js
// import { useRouter } from "next/router";
//sqlitecloud hooks
import { useCustomRouter } from "@custom-hooks/useCustomRouter";
//MIRA pro
import reduceChildRoutes from "./reduceChildRoutes";

const SidebarNavList = (props) => {
  const { pages, depth, isProjectsHome, isMobile, hideSideBarOnDesk } = props;
  // const { pathname } = useRouter();
  // const { query } = useRouter(); //Added by SQLite
  const { query, pathname } = useCustomRouter();
  const childRoutes = pages.reduce(
    (items, page) =>
      reduceChildRoutes({
        items,
        page,
        currentRoute: pathname,
        depth,
        query: query,
        isProjectsHome,
        isMobile,
        hideSideBarOnDesk,
      }), //Added query and itemIndex by SQLite
    []
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
