//react
import { useRef } from "react";
//mui components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//sqlitecloud components
import Support from "@generic-components/Support";

const UpgradingBanner = () => {
  //handle support modal opening
  const supportRef = useRef(null);
  const handleOpenSupport = () => {
    supportRef.current.open();
  };
  return (
    <Box>
      <Typography
        as="div"
        variant="16px-med"
        sx={{ mb: 2 }}
        align="left"
        gutterBottom={true}
      >
        We're upgrading our infrastructure to serve you better!
      </Typography>
      <Typography
        as="div"
        variant="14px-light"
        align="left"
        gutterBottom={false}
      >
        During this time, you may experience intermittent service disruptions.
      </Typography>
      <Typography
        as="div"
        variant="14px-light"
        align="left"
        sx={{ mb: 2 }}
        gutterBottom={true}
      >
        We appreciate your patience and understanding as we work to enhance our
        systems.
      </Typography>
      <Typography
        as="div"
        variant="14px-light"
        align="left"
        gutterBottom={false}
      >
        <span style={{ fontWeight: 500 }}>Date</span>: Thursday, October 24th,
        2024
      </Typography>
      <Typography
        as="div"
        variant="14px-light"
        align="left"
        sx={{ mb: 2 }}
        gutterBottom={true}
      >
        <span style={{ fontWeight: 500 }}>Expected Duration</span>: Two hours
      </Typography>
      <Typography
        as="div"
        variant="14px-light"
        align="left"
        sx={{ mb: 4 }}
        gutterBottom={true}
      >
        For any immediate concerns, please contact our support team <br /> at{" "}
        <a
          href="mailto:dashboard@sqlitecloud.io"
          style={{
            textDecoration: "none",
            color: "#2175E2",
            fontWeight: "500",
            cursor: "pointer",
          }}
        >
          dashboard@sqlitecloud.io
        </a>{" "}
        or send your message by{" "}
        <span
          onClick={handleOpenSupport}
          style={{ color: "#2175E2", fontWeight: "500", cursor: "pointer" }}
        >
          clicking here
        </span>
        .
      </Typography>
      <Typography as="div" variant="15px-med" align="left">
        Thank you for your cooperation!
      </Typography>
      <Support supportRef={supportRef} />
    </Box>
  );
};

export default UpgradingBanner;
