//react
import { useContext, useState } from "react";
//style library
import styled from "@emotion/styled";
//mui components
import UpgradingBanner from "@components/Banners/UpgradingBanner";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MuiIconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud components
import CloseNavSVG from "@components/GenericElements/SVG/CloseNavSVG";
import HamburgerNavSVG from "@components/GenericElements/SVG/HamburgerNavSVG";
import ProSvg from "@components/GenericElements/SVG/ProSvg";
import JobsDropDown from "@components/Jobs/JobsDropDown";
import { Search } from "@components/Search/Search";
import OpenDialog from "@generic-components/OpenDialog";
import { UpgradeModal } from "src/app-components/modals/UpgradeModal";
import HelpDropdown from "./HelpDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
//env variables
const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;
//item used to trigger banner dialog
const BannerItem = ({ callback }) => {
  return (
    <Button onClick={callback} sx={{ mr: 2 }} variant="simple-text-warning">
      Upgrading Infrastructure
    </Button>
  );
};
const BannerDialog = () => {
  return (
    <OpenDialog
      size="medium-modal"
      style="custom"
      decorationVariant={0}
      actionComponent={<BannerItem />}
    >
      <Box>
        <UpgradingBanner />
      </Box>
    </OpenDialog>
  );
};

const IconButton = styled(MuiIconButton)``;

const Menu = ({ open, isMobile, onDrawerToggle }) => {
  //get theme
  const theme = useTheme();
  //enable banner
  const enableBanner = true;
  //get simulation generic user from context
  const { simulateGenericUser, setSimulateGenericUser } =
    useContext(StateContext);
  //define reference to open upgrade to pro banner
  const [isOpen, setIsOpen] = useState(false);
  //render
  return (
    <>
      <UpgradeModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Grid
        container
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        columnGap={4}
      >
        <Grid item>
          {UPGRADING_INFRA &&
            UPGRADING_INFRA.toLocaleLowerCase() === "true" && <BannerDialog />}
        </Grid>
        <Grid item>
          {!simulateGenericUser && (
            <Button
              variant="simple-text"
              sx={{
                pointerEvents: "none",
                background: theme.palette.error.light,
                color: "white",
              }}
            >
              Experimental
            </Button>
          )}
        </Grid>
        <Grid item>
          <Button
            onClick={() => setIsOpen(true)}
            variant="dark-blue-action"
            startIcon={<ProSvg />}
          >
            Upgrade
          </Button>
        </Grid>
        {!isMobile && (
          <Search
            style="only-icon"
            targetLink="_blank"
            relativeUrl={false}
            hiddenClass="opacity-0"
            searchSource="doc"
            modalInputPlaceholder="Search SQLite Cloud"
          />
        )}
        {isMobile && !open && (
          <Search
            style="only-icon"
            targetLink="_blank"
            relativeUrl={false}
            hiddenClass="opacity-0"
            searchSource="doc"
            modalInputPlaceholder="Search SQLite Cloud"
          />
        )}
        {!isMobile && (
          <Grid item>
            <HelpDropdown />
          </Grid>
        )}
        {!isMobile && (
          <Grid item>
            <NavbarUserDropdown />
          </Grid>
        )}
        {<JobsDropDown />}
        {isMobile && !open && (
          <Grid item>
            {
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
                sx={{ margin: 0, padding: 0 }}
                disableRipple={true}
              >
                <HamburgerNavSVG />
              </IconButton>
            }
          </Grid>
        )}
        {isMobile && open && (
          <Grid item>
            {
              <IconButton
                color="inherit"
                aria-label="Close drawer"
                onClick={onDrawerToggle}
                size="large"
                sx={{ margin: 0, padding: 0 }}
                disableRipple={true}
              >
                <CloseNavSVG />
              </IconButton>
            }
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Menu;
