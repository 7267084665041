//react component
import React, { useRef } from "react";
//mui components
import ChatIcon from "@mui/icons-material/Chat";
import SupportIcon from "@mui/icons-material/Support";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
//sqlitecloud compoments;
import HelpSvg from "@components/GenericElements/SVG/HelpSvg";
import SmallDropdownSvg from "@components/GenericElements/SVG/SmallDropdownSvg";
import Feedback from "@generic-components/Feedback";
//sqlitecloud libs
import CommunityIcon from "@layouts/svg-icon/communicty-icon.svg";
import ContactUsIcon from "@layouts/svg-icon/contact-us-icon.svg";

function HelpDropdown({ isMobile = false }) {
  //handle dropdown menu opening
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };
  //handle feedback modal opening
  const feedbackRef = useRef(null);
  const handleOpenFeedback = () => {
    closeMenu();
    feedbackRef.current.open();
  };
  const communityClick = () => {
    window.open("https://github.com/orgs/sqlitecloud/discussions/", "_blank");
  };
  if (isMobile) {
    return (
      <>
        <IconButton
          sx={{ padding: 0 }}
          onClick={communityClick}
          color="primary"
          disableFocusRipple={true}
          disableRipple={true}
        >
          <Box
            padding={"0.75rem 1.75rem 0.75rem 1rem"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={"0.5rem"}
            display={"inline-flex"}
          >
            <CommunityIcon />
            <Typography
              fontFamily={"SuisseIntl"}
              variant="14px-reg"
              fontWeight={"450"}
            >
              Community
            </Typography>
          </Box>
        </IconButton>

        <IconButton
          sx={{ padding: 0 }}
          onClick={handleOpenFeedback}
          color="primary"
          disableFocusRipple={true}
          disableRipple={true}
        >
          <Box
            padding={"0.75rem 1.75rem 0.75rem 1rem"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={"0.5rem"}
            display={"inline-flex"}
          >
            <ContactUsIcon />
            <Typography
              fontFamily={"SuisseIntl"}
              variant="14px-reg"
              fontWeight={"450"}
            >
              Contact Us
            </Typography>
          </Box>
        </IconButton>
        <Feedback feedbackRef={feedbackRef} />
      </>
    );
  }
  return (
    <>
      <Tooltip title={"Support"}>
        <span>
          <IconButton
            sx={{ padding: 0 }}
            aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
            aria-haspopup="true"
            onClick={toggleMenu}
            color="primary"
            disableFocusRipple={true}
            disableRipple={true}
          >
            <Grid
              container
              flexDirection={"row"}
              justifyContent={"centet"}
              alignItems={"center"}
              columnGap={1}
            >
              <HelpSvg />
              <SmallDropdownSvg />
            </Grid>
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={communityClick}>
          <SupportIcon sx={{ marginRight: 2, width: "20px" }} />
          Community
        </MenuItem>
        <MenuItem onClick={handleOpenFeedback}>
          <ChatIcon sx={{ marginRight: 2, width: "20px" }} />
          Contact Us
        </MenuItem>
      </Menu>
      <Feedback feedbackRef={feedbackRef} />
    </>
  );
}

export default HelpDropdown;
