//react
import { useContext, useEffect, useRef } from "react";
//swr
import { useSWRConfig } from "swr";
//css library
import styled from "@emotion/styled";
//mui components
import Badge from "@mui/material/Badge";
import MuiLinearProgress from "@mui/material/LinearProgress";
import MuiPopover from "@mui/material/Popover";
import { spacing } from "@mui/system";
const LinearProgress = styled(MuiLinearProgress)(spacing);
const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.neutral.lightGrey};
  }
`;
const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.palette.secondary.main};
    color: ${(props) => props.theme.palette.primary.white};
  }
`;
//sqlitecloud context
import { StateContext } from "@context/StateContext";
//sqlitecloud lib
import { getJobStatus } from "@lib/dataUtils";
//sqlitecloud hooks
import { useGetActualProjectId, useGetJobNodes } from "@custom-hooks/useApi";
//sqlitecloud components

function JobsDropDown({}) {
  //get actual project id
  const projectId = useGetActualProjectId();
  //get from context state to hendle jobs refresh interval
  const { jobsRefreshInterval, setJobsRefreshInterval } =
    useContext(StateContext);
  //swr
  const { mutate } = useSWRConfig();
  //read jobs nodes
  const getJobsNodes = useGetJobNodes(jobsRefreshInterval);
  //the useRef Hook allows you to persist jobs status between renders
  const prevJobsStatusRef = useRef();
  useEffect(() => {
    if (getJobsNodes.jobNodes) {
      var testIsInProgress = false;
      var jobsStatus = new Map();
      getJobsNodes.jobNodes.forEach((job) => {
        //extract job status
        let status = getJobStatus(job);
        if (
          prevJobsStatusRef.current &&
          prevJobsStatusRef.current.get(job.job_id) == "progress" &&
          status == "completed"
        ) {
          mutate([`/api/projects/${projectId}/nodes`, "useGetNodes"]);
          mutate([`/api/projects`, "useGetProjects"]);
        }
        if (
          prevJobsStatusRef.current &&
          prevJobsStatusRef.current.get(job.job_id) == "progress" &&
          status == "error"
        ) {
        }
        jobsStatus.set(job.job_id, status);
        if (status !== "completed" && status !== "error") {
          testIsInProgress = true;
        }
      });
      prevJobsStatusRef.current = jobsStatus;
      if (testIsInProgress) {
        setJobsRefreshInterval(10000);
      } else {
        setJobsRefreshInterval(false);
      }
    }
  }, [getJobsNodes, projectId, mutate]);

  return <div></div>;
}

export default JobsDropDown;
